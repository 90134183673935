import React from "react";
import backgroundImage from "../../assets/net-zero.jpg";
import leafImage from "../../assets/leaf.png";
import { scrollToBottom, useScroll } from "../../util/util";
import classes from "./NetZero.module.css";

function NetZero({ scroll }: any) {
  const myRef = useScroll(scroll);
  return (
    <>
      <div ref={myRef} className={classes.wrapper}>
        <div
          className={classes.content}
          style={{
            backgroundImage: `url('${backgroundImage}')`,
          }}
        >
          <div className={classes.titleTextWrapper}>
            <div className={classes.titleText}>
              <span className={classes.title}>Net Zero Solutions</span>
              <span className={classes.text}>
                Unique Solutions only
                <br />
                for big consumers
              </span>
            </div>
          </div>
        </div>
        <div
          className="horizontal-separator"
          style={{ backgroundColor: "#F8EAE3" }}
        />
      </div>
      <div className={classes.detailWrapper}>
        <span className={classes.detailTitle}>
          Our most comprehensive tool
          <br />
          on your journey of carbon neutral position.
        </span>
        <span className={classes.detailText}>
          Designed for fixing the energy bill 6 months for 20 to 40 years. It is
          designed to lower the energy bill while
          <br /> fixing it. It is designed to create the carbon neutral position
          in 6 months.
        </span>
        <span className={classes.detailText2}>Sounds challenging?</span>
        <div className={classes.leafImgWrapper}>
          <img src={leafImage} alt="leaf" className={classes.leafImg} />
        </div>
        <span className={classes.detailText3}>Actually not.</span>
        <div className={classes.gradientTextWrapper}>
          <span className={`${classes.detailText4} ${classes.detailGradient}`}>
            Completely data based approach.
          </span>
          <span className={`${classes.detailText5} ${classes.detailGradient}`}>
            Very solid plan. Easy to explain.
          </span>
        </div>
        <div className={classes.detailText6} onClick={scrollToBottom}>
          <span>Contact us</span>
        </div>
        <div
          className="horizontal-separator"
          style={{ backgroundColor: "#EECBFF" }}
        />
      </div>
    </>
  );
}

export default NetZero;
