import React from "react";
import backgroundImage from "../../assets/commercial-due-diligence.jpg";
import { useScroll } from "../../util/util";
import classes from "./CommercialDueDiligence.module.css";

function CommercialDueDiligence({ scroll }: any) {
  const myRef = useScroll(scroll);
  return (
    <div ref={myRef} className={classes.contentWrapper}>
      <div
        className={classes.content}
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <div className={classes.titleWrapper}>
          <span className={classes.title1}>Due</span>
          <span className={classes.title2}>Diligence.</span>
        </div>
        <span className={classes.text}>
          Designed for companies who would like to make some investment to
          Turkish power plant assets.
          <br />
          Our tool allows our customers to get a pre-feasibility of the targeted
          assets or asset classes before a deep dive of due-diligence which
          allows
          <br />
          them to save lots of time and money on investing to the asset or
          assets.
        </span>
      </div>
    </div>
  );
}

export default CommercialDueDiligence;
