import React, { useEffect, useRef } from 'react';
import lamp from '../../assets/lamp.jpg';
import thunder from '../../assets/thunder.png';
import { IoIosArrowDown } from 'react-icons/io';
import { scrollToRef, useHeaderHeight } from '../../util/util';
import classes from './Content.module.css';
import { useMedia } from 'react-media';

const maxLineWidth = 750;
const minLineWidth = 170;
const leftText = [
  { words: 'on everything that we have done since', margin: 215 },
  { words: 'the beginning. In fact the creation of', margin: 200 },
  { words: 'the company relies on the product cre-', margin: 180 },
  { words: 'ated before the establishment of the', margin: 165 },
  { words: 'company. At the end of 2008 when', margin: 150 },
  { words: 'we developed the first Power Market', margin: 140 },
  { words: 'forecasting tool to enter into agree-', margin: 120 },
  { words: 'ments with market participants we', margin: 110 },
  { words: 'have opened Navitas Enerji at the', margin: 95 },
  { words: 'beginning of 2009. At our jour-', margin: 82 },
  { words: 'ney we have achieved so', margin: 95 },
  { words: 'many pioneer positions at', margin: 95 },
  { words: 'the market. The first price', margin: 85 },
  { words: 'forecasting tool, the', margin: 85 },
  { words: 'first electricity con-', margin: 90 },
  { words: 'sumer aggregation', margin: 90 },
  { words: 'service, the first', margin: 90 },
  { words: 'power market', margin: 90 },
  { words: 'price in-', margin: 90 },
  { words: 'dexed', margin: 90 },
];

const rightText = [
  { words: 'contracts of the Turkish Power Market and', margin: 200 },
  { words: 'six different derivatives of it. First elec-', margin: 190 },
  { words: 'tricity consumer CRM system that al-', margin: 175 },
  { words: 'lowed us to create a dynamic portfo-', margin: 165 },
  { words: 'lio risk management system based', margin: 150 },
  { words: 'on different types of electricity', margin: 140 },
  { words: 'sales contracts, with our represen-', margin: 120 },
  { words: 'tation activities first introduction', margin: 110 },
  { words: 'of OTC markets to Turkish', margin: 95 },
  { words: 'Power Market. To stay at the', margin: 90 },
  { words: 'pioneer position Navitas', margin: 98 },
  { words: 'made its full investment', margin: 95 },
  { words: 'to the data substructure.', margin: 90 },
  { words: 'Today by using our', margin: 92 },
  { words: 'comprehensive data', margin: 95 },
  { words: 'environment in-', margin: 95 },
  { words: 'stead of follow-', margin: 95 },
  { words: 'ing the sales', margin: 95 },
  { words: 'streams on', margin: 95 },
  { words: 'products', margin: 95 },
];

const LampParagraph = ({ text, right }: any) => {
  const isSmallScreen = useMedia({ query: '(max-width: 1024px)' });
  const ratio = isSmallScreen ? 0.3 : 1;
  return (
    <div className={right ? classes.lampParagraphRight : classes.lampParagraph}>
      {text.map((t: any, i: number) => {
        return (
          <div
            key={i}
            className={classes.lampText}
            style={{
              marginLeft: right ? t.margin * ratio : 0,
              marginRight: right ? 0 : t.margin * ratio,
              width:
                (maxLineWidth -
                  i * ((maxLineWidth - minLineWidth) / text.length) -
                  t.margin) *
                ratio,
            }}
          >
            {t.words.split(' ').map((w: any, j: any) => {
              return <span key={`w-${i}-${j}`}>{w}</span>;
            })}
          </div>
        );
      })}
    </div>
  );
};

function Content() {
  const myRef = useRef<any>(null);
  const headerHeight = useHeaderHeight();

  useEffect(() => {
    myRef.current.scrollTo(
      (myRef.current.scrollWidth - myRef.current.clientWidth) / 2,
      window.scrollY
    );
  }, []);

  return (
    <div className="relative">
      <div ref={myRef} className={classes.storyWrapper}>
        <div className={classes.story}>
          <span className={classes.storyTitle}>Our Story.</span>
          <div className={classes.storyText}>
            <div>
              When we look at our past 13 years we can easily say we have
            </div>
            <div>
              enjoyed to grow parallel to the Turkish Power Market. Ups and
              downs
            </div>
            <div>reshaping the products with this very dynamic market.</div>
            <div className="mt-6">We have tried to be pioneer</div>
          </div>
        </div>

        <div className={classes.lampContentWrapper}>
          <img src={thunder} alt="thunder" className={classes.thunderImg} />
          <div
            className={classes.lampContent}
            style={{
              backgroundImage: `url('${lamp}')`,
            }}
          >
            <LampParagraph text={leftText} />
            <LampParagraph text={rightText} right />
          </div>
          <div className={classes.lampBottomText}>we</div>
        </div>
      </div>
      <div className={classes.remainingText}>
        are developing products before our customers <br />
        understand their needs to it. We are a consultancy company and your
        unique solution
        <br />
        provider for your needs at the Turkish Power Market.
      </div>
      <div
        className={classes.indicator}
        onClick={() =>
          scrollToRef(myRef, headerHeight, {
            top: -64,
            behavior: 'smooth',
          })
        }
      >
        <IoIosArrowDown />
      </div>
    </div>
  );
}

export default Content;
