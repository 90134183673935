import React, { useEffect } from "react";
import backgroundImage from "../../assets/nost.jpg";
import { useScroll } from "../../util/util";
import classes from "./Nost.module.css";

function Nost({ scroll }: any) {
  const myRef = useScroll(scroll);

  useEffect(() => {
    myRef.current.scrollTo(
      (myRef.current.scrollWidth - myRef.current.clientWidth) / 2,
      window.scrollY
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={myRef} className={classes.wrapper}>
        <div
          className={classes.content}
          style={{
            backgroundImage: `url('${backgroundImage}')`,
          }}
        >
          <div className={classes.titleText}>
            <span className={classes.title}>Nost.</span>
            <span className={classes.text}>Our price forecast solution.</span>
          </div>
          <div className={classes.overlayTextWrapper}>
            <div className={classes.otLeft}>
              <div className={classes.shape1} />
              <p className={`${classes.overlayText} text-right`}>
                Powered <br />
                Lots and lots of data.
                <br />
                creating forecasts for future
              </p>
            </div>
            <div className={classes.otCenter}>
              <div className={classes.shape2} />
              <div className={classes.shape3} />
              <p
                className={`${classes.overlayText} ${classes.centerIfNotJustify}`}
              >
                with machine learning and fed <br />
                From first day to ten years it gives <br />
                power market prices. We have started
              </p>
            </div>
            <div className={classes.otRight}>
              <div className={classes.shape4} />
              <p className={`${classes.overlayText} text-left`}>
                with data. <br />
                us the chance of
                <br />
                with Turkish Power Market.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footerWrapper}>
        <span className={classes.footerText}>
          And now testing it for some European Power Markets as well. Some of
          our clients really benefited from our results. Give us a call so
          <br /> we can share with you our references. You can learn the results
          of our forecasts from them.
        </span>
      </div>
    </>
  );
}

export default Nost;
