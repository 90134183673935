import React from "react";
import { useParams } from "react-router-dom";
import CommercialDueDiligence from "../components/consultancy/CommercialDueDiligence";
import InfoPage from "../components/consultancy/InfoPage";
import MarketPenetrationStrategy from "../components/consultancy/MarketPenetrationStrategy";
import Header from "../components/header/Header";

function Consultancy() {
  const { scrollTo } = useParams();
  return (
    <div className="flex flex-col justify-start">
      <Header />
      <MarketPenetrationStrategy
        scroll={scrollTo === "market-penetration-strategy"}
      />
      <InfoPage />
      <CommercialDueDiligence
        scroll={scrollTo === "commercial-due-diligence"}
      />
    </div>
  );
}

export default Consultancy;
