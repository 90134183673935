import React from "react";
import { Layout, Popover } from "antd";
import logo from "../../assets/logo.png";
import { AiFillThunderbolt } from "react-icons/ai";
import { Link } from "react-router-dom";
import { scrollToBottom } from "../../util/util";
import { navLinks } from "./Header";
import classes from "./DefaultHeader.module.css";

const { Header } = Layout;

const Item = ({ label, navigateTo, submenu }: any) => {
  const itemLinkComponent = (
    <Link to={navigateTo}>
      <div className={classes.headerItemWrapper}>
        <span>{label}</span>
      </div>
    </Link>
  );
  if (!submenu) {
    return itemLinkComponent;
  }

  return (
    <>
      <Popover
        placement="bottomLeft"
        title={null}
        content={
          <>
            {submenu.map((item: any, i: number) => {
              return (
                <Link key={i} to={item.navigateTo}>
                  <div className={classes.popoverItemWrapper}>
                    <AiFillThunderbolt />
                    <span>{item.label}</span>
                  </div>
                </Link>
              );
            })}
          </>
        }
      >
        {itemLinkComponent}
      </Popover>
    </>
  );
};

function DefaultHeader() {
  return (
    <div className={classes.headerWrapper}>
      <Header>
        <div className={classes.header}>
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <div className={classes.navLinksWrapper}>
            <div className={classes.navLinks}>
              {navLinks.map((l, i) => (
                <Item key={i} {...l} />
              ))}
              <div
                className={classes.headerItemWrapper}
                onClick={scrollToBottom}
              >
                <span>CONTACT</span>
              </div>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
}

export default DefaultHeader;
