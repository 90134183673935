import React from "react";
import pencilImage from "../../assets/pencil.png";
import laptopImage from "../../assets/laptop.png";
import classes from "./InfoPage.module.css";
import { scrollToBottom } from "../../util/util";

function InfoPage() {
  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>
        Worked for lots of international
        <br />
        companies.
      </span>
      <div className={classes.text}>
        <span>
          For funds, investors, utilities we have developed, and worked with
          them on their targets on their final
          <br />
          targets for Turkish Power Market. We have developed them certain,
          tools, reports and
          <br />
          consultancy services on their journey at the Turkish Power Market.
        </span>
        <span className={classes.textPurple}>
          For our references and to discuss what can do
          <br />
          for your company{" "}
          <span className={classes.textBlue} onClick={scrollToBottom}>
            contact us.
          </span>
        </span>
      </div>
      <div className={classes.pencilImgWrapper}>
        <img
          src={pencilImage}
          alt="pencil"
          className={classes.pencilImg}
          style={{ maskImage: `url('${laptopImage}')` }}
        />
      </div>
      <div
        className={classes.laptopImgWrapper}
        style={{
          backgroundImage: `url('${laptopImage}')`,
        }}
      >
        <div className={classes.laptopTextWrapper}>
          <span className={classes.laptopTextTitle}>A Flexible Approach</span>
          <span className={classes.laptopText}>To Turkish Power Market.</span>
        </div>
      </div>
      <span className={classes.commercialText}>Commercial</span>
      <div
        className="horizontal-separator"
        style={{ backgroundColor: "#8f84c7" }}
      />
    </div>
  );
}

export default InfoPage;
