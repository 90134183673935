import { useParams } from "react-router-dom";
import Header from "../components/header/Header";
import NetZero from "../components/solutions/NetZero";
import Nost from "../components/solutions/Nost";
import VM from "../components/solutions/VM";

function Solutions(props: any) {
  const { scrollTo } = useParams();
  return (
    <div className="flex flex-col justify-start">
      <Header />
      <NetZero scroll={scrollTo === "net-zero"} />
      <VM scroll={scrollTo === "vm"} />
      <Nost scroll={scrollTo === "nost"} />
    </div>
  );
}

export default Solutions;
