import { useCallback, useEffect, useRef } from "react";
import { useMedia } from "react-media";

export const scrollToRef = (
  ref: any,
  headerHeight: number,
  options?: ScrollToOptions
) => {
  if (!ref.current) {
    return;
  }

  const headerOffset = headerHeight + (options?.top ?? 0);
  const elementPosition = ref.current.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({ ...options, top: offsetPosition });
};

export const useScroll = (
  scroll = true,
  options = { top: 0 } as ScrollToOptions
) => {
  const headerHeight = useHeaderHeight();
  const myRef = useRef<any>(null);
  const executeScroll = useCallback(() => {
    scrollToRef(myRef, headerHeight, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (scroll) {
      executeScroll();
    }
  }, [executeScroll, scroll]);

  if (scroll && myRef.current) {
    executeScroll();
  }

  return myRef;
};

export const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth",
  });
};

export const useHeaderHeight = () => {
  const isSmallScreen = useMedia({ query: "(max-width: 1024px)" });
  return isSmallScreen ? 48 : 64;
};
