import React from "react";
import backgroundImage from "../../assets/homepage.jpg";
// import { FileOutlined } from "@ant-design/icons";
import classes from "./Content.module.css";

function Content() {
  return (
    <div
      className={classes.contentWrapper}
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
    >
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <span className={classes.thinText}>DATA DRIVEN</span>
          <span className={classes.boldText}>Power Market</span>
          <span className={`${classes.boldText} ${classes.ls0085}`}>
            Solutions 3.0
          </span>
        </div>
        {/* <div className={`${classes.buttonsWrapper} noselect`}>
          <div className={`${classes.buttonWrapper} ${classes.button}`}>
            Learn More
          </div>
          <div className={classes.buttonWrapper}>
            <div className={classes.button}>Report Examples </div>
            <div className={classes.buttonLogo}>
              <FileOutlined />
            </div>
          </div>
        </div> */}
      </div>
      <div className="horizontal-separator" style={{ height: 1 }} />
    </div>
  );
}

export default Content;
