import React, { useEffect, useState } from "react";

import { Layout } from "antd";
import logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import "@animated-burgers/burger-squeeze/dist/styles.css";
import classes from "./MobileHeader.module.css";
import { navLinks } from "./Header";
import { scrollToBottom } from "../../util/util";

const { Header } = Layout;

const BurgerMenu = ({ isMenuVisible, setMenuVisible }: any) => {
  return (
    <div className={classes.burgerMenu}>
      <div
        className={`burger burger-squeeze ${isMenuVisible ? "open" : ""}`}
        style={{ fontSize: 6 }}
        onClick={() => setMenuVisible(!isMenuVisible)}
      >
        <div className="burger-lines" />
      </div>
    </div>
  );
};

const Item = ({ label, navigateTo, submenu, onClick }: any) => {
  if (!submenu) {
    return (
      <Link to={navigateTo} onClick={onClick}>
        <div className={classes.headerItemWrapper}>
          <span>{label}</span>
        </div>
      </Link>
    );
  }

  return (
    <>
      {submenu.map((l: any, i: number) => {
        return <Item key={`${label}-${i}`} {...l} onClick={onClick} />;
      })}
    </>
  );
};

const NavMenu = ({ setMenuVisible }: any) => {
  return (
    <div className={classes.navLinks}>
      {navLinks
        .filter((_, i) => i > 0)
        .map((l, i) => (
          <Item key={i} {...l} onClick={() => setMenuVisible(false)} />
        ))}
      <div
        className={classes.headerItemWrapper}
        onClick={() => {
          scrollToBottom();
          setMenuVisible(false);
        }}
      >
        <span>Contact Us</span>
      </div>
    </div>
  );
};

function MobileHeader() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setMenuVisible(false);
  }, [pathname]);

  return (
    <div className={classes.headerWrapper}>
      <Header>
        <div className={classes.header}>
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
          <BurgerMenu {...{ isMenuVisible, setMenuVisible }} />
        </div>
      </Header>
      <div
        className={`${classes.menu} ${isMenuVisible ? classes.menuOpen : ""}`}
      >
        {isMenuVisible && <NavMenu setMenuVisible={setMenuVisible} />}
      </div>
    </div>
  );
}

export default MobileHeader;
