import React from "react";
import Header from "../components/header/Header";

function NotFound() {
  return (
    <>
      <Header />
      <div className="text-white text-4xl font-bold w-full h-screen flex justify-center items-center bg-red-500">
        404 NotFound
      </div>
    </>
  );
}

export default NotFound;
