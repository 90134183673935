import React from "react";
import backgroundImage from "../../assets/vm.jpg";
import futurePredictImage from "../../assets/future-predict.jpg";
import { useScroll } from "../../util/util";
import classes from "./VM.module.css";

function VM({ scroll }: any) {
  const myRef = useScroll(scroll);
  return (
    <>
      <div ref={myRef} className={classes.wrapper}>
        <div
          className={classes.content}
          style={{
            backgroundImage: `url('${backgroundImage}')`,
          }}
        >
          <div className={classes.titleTextWrapper}>
            <div className={classes.titleText}>
              <span className={classes.title1}>Virtual</span>
              <span className={classes.title2}>Market.</span>
              <span className={`${classes.text} ${classes.gradient}`}>
                Our simulated environment.
              </span>
            </div>
          </div>
        </div>
        <div
          className="horizontal-separator"
          style={{ backgroundColor: "#EBD4E7" }}
        />
      </div>
      <div className={classes.detailWrapper}>
        <div className={classes.detailContent}>
          <span className={classes.detailTitle}>
            It's a living simulation
            <br />
            enviroment.
          </span>
          <span className={classes.detailText}>
            We duplicate all the information that Turkish Energy Exchange
            provides us and we update it everyday. Actually sometimes every hour
            or half an hour or every 15 minutes down to every minute depends on
            the info. It is a living simulation environment. It gives us the
            capability to recreate the market conditions with every scenario
            that we can think and gives us every possible perspective to look at
            the market as we want.
          </span>
          <div
            className={classes.fpContentWrapper}
            style={
              {
                // backgroundImage: `url('${futurePredictImage}')`,
              }
            }
          >
            <img
              src={futurePredictImage}
              alt="future-predict"
              className={classes.fpImg}
            />
            <div className={classes.fpContent}>
              <div className={classes.fpTextWrapper}>
                <span className={classes.fpText1}>Future</span>
                <span className={classes.fpText2}>Predict.</span>
              </div>
            </div>
          </div>
          <span className={classes.fpText3}>Most flexible choices.</span>
        </div>
      </div>
      <div className={classes.moreWrapper}>
        <div className={classes.moreContent}>
          <span>
            <span className={classes.blackText}>
              It gives us and by us to you
            </span>{" "}
            the chances to test trading ideas, educate your traders without
            loosing any money. It gives a very detailed understanding of every
            reason of every market change and what to expect at the future.
          </span>
          <span>
            <span className={classes.blackText}>
              We can re-simulate past {"&"} present
            </span>
            . We can simulate the future on our environment. As we say it is the
            Virtual duplicate of the real market environment. What we want to
            see and what we want to calculate is there to serve.
          </span>
        </div>
      </div>
      <div className={classes.moreWrapper2}>
        <div className={classes.moreContent2}>
          <span className={classes.moreContent2Text}>
            Some of the things that we have already obtained is pretty amazing.
            Call us so we can explain
            <br />
            you in details what our Virtual Market environment can do for you.
          </span>
        </div>
        <div
          className="horizontal-separator"
          style={{ backgroundColor: "#F8EAE3" }}
        />
      </div>
    </>
  );
}

export default VM;
