import React, { useEffect, useState } from "react";
import DefaultHeader from "./DefaultHeader";
import MobileHeader from "./MobileHeader";
import { useMedia } from "react-media";

export const navLinks = [
  {
    label: "WHO IS NAVITAS?",
    navigateTo: "/",
  },
  {
    label: "CONSULTANCY",
    navigateTo: "/consultancy",
    submenu: [
      {
        label: "Market Penetration Strategy",
        navigateTo: "/consultancy/market-penetration-strategy",
      },
      {
        label: "Commercial Due Diligence",
        navigateTo: "/consultancy/commercial-due-diligence",
      },
    ],
  },
  {
    label: "SOLUTIONS 3.0",
    navigateTo: "/solutions",
    submenu: [
      {
        label: "Net Zero",
        navigateTo: "/solutions/net-zero",
      },
      {
        label: "Virtual Market",
        navigateTo: "/solutions/vm",
      },
      {
        label: "Nost",
        navigateTo: "/solutions/nost",
      },
    ],
  },
];

type Props = {
  top?: number;
};

const useScrollListener = (top: number, isSmallScreen: boolean) => {
  if (isSmallScreen) {
    top = 0;
  }

  const [locationY, setLocationY] = useState(top);
  const listenScrollEvent = () => {
    setLocationY(Math.max(top - window.scrollY, 0));
  };

  useEffect(() => {
    if (top <= 0) {
      return;
    }

    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (top <= 0) {
    return 0;
  }

  return locationY;
};

function Header({ top = 0 }: Props) {
  const isSmallScreen = useMedia({ query: "(max-width: 1024px)" });
  const marginTop = useScrollListener(top, isSmallScreen);
  return (
    <div
      style={{
        marginTop,
        height: top > 0 ? Math.max(top, isSmallScreen ? 0 : 64) : 0,
      }}
    >
      <DefaultHeader />
      <MobileHeader />
    </div>
  );
}

export default Header;
