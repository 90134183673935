import React from "react";
import backgroundImage from "../../assets/market-penetration-strategy.jpg";
import { useScroll } from "../../util/util";
import classes from "./MarketPenetrationStrategy.module.css";

function MarketPenetrationStrategy({ scroll }: any) {
  const myRef = useScroll(scroll);
  return (
    <div ref={myRef} className={classes.wrapper}>
      <div className={classes.imageHeaderTop} />
      <div
        className={classes.titleWrapper}
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <span className={classes.text1}>Market</span>
        <span className={classes.text2}>Penetration</span>
        <span className={classes.text2}>Strategy.</span>
        <span className={classes.text3}>
          Designed for international companies.
        </span>
      </div>
      <div
        className="horizontal-separator"
        style={{ backgroundColor: "#f4d6cb" }}
      />
    </div>
  );
}

export default MarketPenetrationStrategy;
