import React from "react";
import { Layout } from "antd";
import AppRouter from "./AppRouter";
import AppFooter from "./components/footer/AppFooter";

function App() {
  return (
    <Layout className="layout flex">
      <AppRouter />
      <AppFooter />
    </Layout>
  );
}

export default App;
