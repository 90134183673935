import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import classes from "./AppFooter.module.css";

const { Footer } = Layout;
function AppFooter() {
  return (
    <Footer className={classes.footerWrapper}>
      <div className={classes.footer}>
        <div className={classes.footerContent}>
          <div className={classes.contactUsWrapper}>
            <p className={classes.footerTitle}>Contact Us</p>
            <div className={classes.contactUs}>
              <span>ODTÜ Teknokent BİM No: 1260 - 183 </span>
              <span>06510 Mustafa Kemal Mah.</span>
              <span>Çankaya/ANKARA</span>
              <span>
                <a href="mailto:info@ne.com.tr">info@ne.com.tr</a>
              </span>
            </div>
            <img src={logo} alt="Logo" className="logo mt-10" />
          </div>
          <div className={classes.navLinksWrapper}>
            <div>
              <p className={classes.footerTitle}>Consultancy</p>
              <div className={classes.navLink}>
                <Link to="/consultancy/market-penetration-strategy">
                  <span>Market Penetration Strategy</span>
                </Link>
                <Link to="/consultancy/commercial-due-diligence">
                  <span>Commercial Due Diligence</span>
                </Link>
              </div>
            </div>
            <div>
              <p className={classes.footerTitle}>Solutions 3.0</p>
              <div className={classes.navLink}>
                <Link to="/solutions/net-zero">
                  <span>Net Zero</span>
                </Link>
                <Link to="/solutions/vm">
                  <span>Virtual Market</span>
                </Link>
                <Link to="/solutions/nost">
                  <span>Nost</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.navitasText}>
          Navitas Enerji Teknoloji Anonim Şirketi ©2009
        </div>
      </div>
    </Footer>
  );
}

export default AppFooter;
