import React from "react";
import Header from "../components/header/Header";
import WelcomeContent from "../components/home/Content";
import OurStory from "../components/our-story/Content";

function HomePage() {
  return (
    <>
      <Header top={41} />
      <WelcomeContent />
      <OurStory />
    </>
  );
}

export default HomePage;
