import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Consultancy from "./pages/Consultancy";
import Solutions from "./pages/Solutions";
import NotFound from "./pages/NotFound";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/:scrollTo" element={<HomePage />} />
      <Route path="/consultancy" element={<Consultancy />} />
      <Route path="/consultancy/:scrollTo" element={<Consultancy />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/solutions/:scrollTo" element={<Solutions />} />
    </Routes>
  );
};

export default AppRouter;
